import React, { useEffect, useState } from 'react';
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import { ClusteredDeviceMarkers } from './clustered_device_markers';
import { DistrictMarkers } from './district_markers';
import OverlayParent from './overlays_parent';
import { useDispatch, useSelector } from 'react-redux';
import { displayDevices } from '../../redux/mapStateSlice';
import ImageMapContent from './image_map_content';
import { calculateImageBounds } from '../utils/imageBounds';

// New child component that handles map operations
const MapContent = ({ zoomedDevices, displayedDevices, mapSettings, restrictionBounds }) => {
  const map = useMap();

  useEffect(() => {

    if (mapSettings.display_type === 'image') {
      return;
    }

    const devicesToZoom = zoomedDevices?.length > 0 ? zoomedDevices : displayedDevices;

    if (devicesToZoom?.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
      devicesToZoom.forEach((device) => {
        bounds.extend({ lat: device.lat, lng: device.lng });
      });
      map.fitBounds(bounds, { top: 15, bottom: 15, left: 15, right: 15 });
    }
  }, [zoomedDevices, displayedDevices, map, restrictionBounds]);

  return (
    <>
      <ClusteredDeviceMarkers />
      <DistrictMarkers />
    </>
  );
};

const MultiMap = () => {
  const [restrictionBounds, setRestrictionBounds] = useState(null);
  const dispatch = useDispatch();
  const deviceData = useSelector((state) => state.devices.devices);
  const zoomedDevices = useSelector((state) => state.mapState.zoomedDevices);
  const displayedDevices = useSelector((state) => state.mapState.displayedDevices);
  const mapSettings = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(displayDevices(deviceData));
  }, [deviceData, mapSettings]);

  useEffect(() => {
    let isCancelled = false;

    const imageUrl = mapSettings.image_display_settings.image_url;
    if (!imageUrl) {
      return;
    }

    calculateImageBounds(imageUrl)
      .then(bounds => {
        if (!isCancelled) {
          console.log("Calculated bounds:", bounds);
          console.log(imageUrl);
          setRestrictionBounds(bounds);
        }
      })
      .catch(error => {
        if (!isCancelled) {
          console.error("Error loading image:", error);
        }
      });

    return () => {
      isCancelled = true;
    };
  }, [mapSettings]);

  return (
    <APIProvider apiKey="AIzaSyCBh1WTEHMpJrPNFBzZhplqCpsJlmisigY" version="beta">
      <div className="relative h-full w-full">
        {mapSettings.display_type === 'map' ? ( // type map
          <Map
            defaultZoom={mapSettings.map_display_settings.zoom}
            defaultCenter={mapSettings.map_display_settings.center}
            mapTypeControlOptions={{
              position: 20,
              style: 1,
            }}
            options={{
              disableDefaultUI: false,
              zoomControl: true,
              streetViewControl: true,
              fullscreenControl: false,
              mapId: mapSettings.map_display_settings.mapId,
            }}
            gestureHandling={'greedy'}
            className="w-full h-full"
          >
            <MapContent
              zoomedDevices={zoomedDevices}
              displayedDevices={displayedDevices}
              mapSettings={mapSettings}
              restrictionBounds={restrictionBounds}
            />
          </Map>
        ) : (
          // type image
          <Map
            defaultCenter={{ lat: 0, lng: 0 }} // Set to coordinates 0, 0
            defaultBounds={{north: 0.003, south: -0.003, east: 0.003, west: -0.003}}
            mapTypeControlOptions={{
              position: 20,
              style: 1,
            }}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              streetViewControl: false,
              fullscreenControl: false,
              restriction: {
                latLngBounds: {
                  north: 0.006,
                  south: -0.006,
                  east: 0.006,
                  west: -0.006,
                },
                strictBounds: false,
              }
            }}
            mapId={"a39b4ad57206b5ef"} // blank map id
            gestureHandling={'greedy'}
            className="w-full h-full"
          >
            <ImageMapContent restrictionBounds={restrictionBounds} mapSettings={mapSettings} />
            <MapContent
              zoomedDevices={zoomedDevices}
              displayedDevices={displayedDevices}
              mapSettings={mapSettings}
              restrictionBounds={restrictionBounds}
            />
          </Map>
        )}
        <OverlayParent />
      </div>
    </APIProvider>
  );
};

export default MultiMap;
