import { useRef, memo, useEffect, useState } from 'react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import './MarkerMaker.css';
import MapContent from './components/MapContent';
import InspectorPanel from './components/InspectorPanel';
import { useMarkerManager } from './hooks/useMarkerManager';
import { useSelector } from 'react-redux';
import Controls from './components/Controls';
import ImageMapContent from './ImageMapContent';
import { calculateImageBounds } from '../interactive_map/utils/imageBounds';

// Memoize MapContent
const MemoizedMapContent = memo(MapContent);

export default function MarkerMaker() {
  const mapRef = useRef(null);
  const selectionBoxRef = useRef(null);
  const mapSettings = useSelector((state) => state.settings);

  const handlers = useMarkerManager(mapRef, selectionBoxRef);

  const [restrictionBounds, setRestrictionBounds] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    const imageUrl = mapSettings.image_display_settings.image_url;
    if (!imageUrl) {
      return;
    }

    calculateImageBounds(imageUrl)
      .then(bounds => {
        if (!isCancelled) {
          setRestrictionBounds(bounds);
        }
      })
      .catch(error => {
        if (!isCancelled) {
          console.error("Error loading image:", error);
        }
      });

    return () => {
      isCancelled = true;
    };
  }, [mapSettings]);

  console.log(mapSettings);

  return (
    <APIProvider apiKey="AIzaSyCBh1WTEHMpJrPNFBzZhplqCpsJlmisigY">
      <div>
        <Controls />
        <div style={{ position: 'relative' }} ref={mapRef} className="map-container">
          {mapSettings.display_type === 'map' ? (
            <Map
              defaultZoom={mapSettings.map_display_settings.zoom}
              defaultCenter={mapSettings.map_display_settings.center}
              clickableIcons={false}
              mapTypeControlOptions={{
                position: 20,
                style: 1,
              }}
              options={{
                disableDefaultUI: false,
                zoomControl: true,
                streetViewControl: true,
                fullscreenControl: false,
                mapId: mapSettings.mapId,
                clickableIcons: false,
                disableDoubleClickZoom: true,
                draggableCursor: 'default',
                draggingCursor: 'default'
              }}
              mapId={mapSettings.map_display_settings.mapId}
              style={{ userSelect: 'none' }}
            >
              <MemoizedMapContent
                onMapClick={handlers.handleMapClick}
                onMarkerClick={handlers.handleMarkerClick}
                onMarkerDragEnd={handlers.handleMarkerDragEnd}
              />
            </Map>
          ) : (
            <Map
              defaultCenter={{ lat: 0, lng: 0 }}
              defaultBounds={{ north: 0.003, south: -0.003, east: 0.003, west: -0.003 }}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                streetViewControl: false,
                fullscreenControl: false,
                restriction: {
                  latLngBounds: {
                    north: 0.006,
                    south: -0.006,
                    east: 0.006,
                    west: -0.006,
                  },
                  strictBounds: false,
                }
              }}
              mapId={"a39b4ad57206b5ef"}
              gestureHandling={'greedy'}
              className="w-full h-full"
            >
              <ImageMapContent restrictionBounds={restrictionBounds} mapSettings={mapSettings} />
              <MemoizedMapContent
                onMapClick={handlers.handleMapClick}
                onMarkerClick={handlers.handleMarkerClick}
                onMarkerDragEnd={handlers.handleMarkerDragEnd}
              />
            </Map>
          )}
          <div ref={selectionBoxRef} className="selection-box" />
          <InspectorPanel />
        </div>
      </div>
    </APIProvider>
  );
} 