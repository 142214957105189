// Validator Utility

const validateSpecificEntries = (obj) => {
    const errors = [];
    if (obj.settings && obj.settings.display_type) {
        const displayType = obj.settings.display_type;
        if (displayType !== 'map' && displayType !== 'image') {
            errors.push(
                `Invalid value at settings.display_type: Expected "map" or "image", got "${displayType}"`
            );
        }
    }
    return errors;
};

const validateJsonStructure = (json) => {
    const structure = {
        settings: {
            display_type: 'string',
            map_display_settings: {
                center: { lat: 'number', lng: 'number' },
                zoom: 'number',
                mapId: 'string',
            },
            image_display_settings: {
                image_url: 'string',
            },
        },
        tasks: [
            {
                id: 'number',
                status: 'string',
                aiScore: 'number',
                object: 'string',
                initiatedBy: 'string',
                missionType: 'string',
                dueDate: 'string',
                priority: 'string',
                responsibleTeam: 'string',
                device_id: 'string',
                activities: [
                    { description: 'string', date: 'string', status: 'string' },
                ],
                team: {
                    name: 'string',
                    members: [{ name: 'string', role: 'string' }],
                },
            },
        ],
        devices: [
            {
                lat: 'number',
                lng: 'number',
                status: 'number',
                sensor_id: 'string',
                battery: 'number',
                signal: 'number',
                district: 'string',
                location: 'number',
                type: 'string',
                measures: [
                    {
                        date: 'string',
                        image: 'string',
                        ai_score: 'string',
                        readings: [
                            { key: 'string', value: 'string', status: 'string' },
                        ],
                    },
                ],
                maintenances: [
                    { date: 'string', task: 'string', team: 'string' },
                ],
            },
        ],
        districts: [
            { id: 'string', image: 'string', lat: 'number', lng: 'number', name: 'string' },
        ],
        images: { districts: ['string'], devices: ['string'] },
    };

    const validateObject = (obj, template, path = '') => {
        const errors = [];
        for (const key in template) {
            const currentPath = path ? `${path}.${key}` : key;
            if (!(key in obj)) {
                errors.push(`Missing property: ${currentPath}`);
            } else {
                const value = obj[key];
                const expected = template[key];
                if (typeof expected === 'string') {
                    if (typeof value !== expected) {
                        errors.push(
                            `Type mismatch at ${currentPath}: Expected ${expected}, got ${typeof value}`
                        );
                    }
                } else if (Array.isArray(expected)) {
                    if (!Array.isArray(value)) {
                        errors.push(
                            `Type mismatch at ${currentPath}: Expected array`
                        );
                    } else if (expected.length > 0) {
                        value.forEach((item, index) => {
                            if (typeof item !== 'object' || item === null) {
                                errors.push(
                                    `Type mismatch at ${currentPath}[${index}]: Expected object`
                                );
                            } else {
                                errors.push(
                                    ...validateObject(
                                        item,
                                        expected[0],
                                        `${currentPath}[${index}]`
                                    )
                                );
                            }
                        });
                    }
                } else if (typeof expected === 'object') {
                    if (typeof value !== 'object' || value === null || Array.isArray(value)) {
                        errors.push(
                            `Type mismatch at ${currentPath}: Expected object`
                        );
                    } else {
                        errors.push(
                            ...validateObject(value, expected, currentPath)
                        );
                    }
                }
            }
        }
        return errors;
    };

    const structureErrors = validateObject(json, structure);
    const specificErrors = validateSpecificEntries(json);

    return [...structureErrors, ...specificErrors];
};

export default validateJsonStructure;
