import React, { useEffect } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { useSelector } from 'react-redux';
import { calculateImageBounds } from '../utils/imageBounds';

const ImageMapContent = ({ restrictionBounds, mapSettings }) => {
  const map = useMap();
  const imageUrl = mapSettings.image_display_settings.image_url;

  useEffect(() => {
    if (map && restrictionBounds) {
      const groundOverlay = new window.google.maps.GroundOverlay(
        `images/maps/${imageUrl}`,
        restrictionBounds
      );
      groundOverlay.setMap(map);

      return () => {
        groundOverlay.setMap(null);
      };
    }
  }, [map, imageUrl, restrictionBounds]);

  return null;
};

export default ImageMapContent;
