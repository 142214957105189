export const calculateImageBounds = (imageUrl, centerLat = 0, centerLng = 0) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = `images/maps/${imageUrl}`;

    image.onload = () => {
      const aspectRatio = image.width / image.height;
      const size = 0.005; // Half the size of the square

      let width, height;
      if (aspectRatio > 1) {
        width = size;
        height = size / aspectRatio;
      } else {
        width = size * aspectRatio;
        height = size;
      }

      const bounds = {
        north: centerLat + height,
        south: centerLat - height,
        east: centerLng + width,
        west: centerLng - width,
      };

      resolve(bounds);
    };

    image.onerror = reject;
  });
}; 