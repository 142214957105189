import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  display_type: "image",
  map_display_settings: {
    center: { lat: 0, lng: 0 },
    zoom: 13,
    mapId: "9cc7d3e49ca28f32"
  },
  image_display_settings: {
    image_url: ""
  }
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setMapSettings: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { setMapSettings } = settingsSlice.actions;
export default settingsSlice.reducer; 